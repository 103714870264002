@import "../../style/variable.scss";

.description-zone {
  .note {
    color: getColor("gray-4");
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 3px;
  }

  .color-rate {
    height: 10px;
    width: 10px;

    &.good-rate {
      background: getColor("green-1");
    }
    &.improve-rate {
      background: getColor("orange-1");
    }
    &.bad-rate {
      background: getColor("yellow-1");
    }
    &.edited {
      background: getColor("blue-1");
    }
    &.error {
      background: getColor("red-1");
    }
  }
}
