@import "../../style/variable.scss";

.modal {
  .close-button {
    .close-icon {
      background: #e5e5e5;
      border-radius: 50%;
    }
  }

  .error-modal-description {
    font-size: 14px;
    &:not(span) {
      color: getColor("gray-2");
    }
  }

  .bg-alert-icon,
  .bg-warning-icon {
    background: #f5f5f5;
    height: 50px;
    width: 50px;
    border-radius: 8px;
  }
}
