/* Exo thin font from Google. */
@import url(https://fonts.googleapis.com/css?family=Exo:100);
@import "~bootstrap/scss/bootstrap";
@import "../style/variable.scss";

/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
$bg-url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC";
$bg-width: 50px;
$bg-height: 50px;

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}

// /* Main styles */
html,
body {
  margin: 0;
  padding: 0;
}

body {
  // overflow: hidden;
  //   margin-top: 13.5rem;
  // font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;

  height: 100%;

  &:has(div > div.upload-page, div.preview-ai-result-page) {
    background: url("../assets//Background.png");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
  }

  /* img size is 50x50 */

  // -webkit-animation: bg-scrolling-reverse 0.92s infinite; /* Safari 4+ */
  // -moz-animation: bg-scrolling-reverse 0.92s infinite; /* Fx 5+ */
  // -o-animation: bg-scrolling-reverse 0.92s infinite; /* Opera 12+ */
  // animation: bg-scrolling-reverse 0.92s infinite; /* IE 10+ */
  // -webkit-animation-timing-function: linear;
  // -moz-animation-timing-function: linear;
  // -o-animation-timing-function: linear;
  // animation-timing-function: linear;

  .btn {
    &.btn-primary {
      background: getColor("primary");
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .font-bold-600 {
    font-weight: 600;
  }

  .font-bold-700 {
    font-weight: 700;
  }

  .font-bold-500 {
    font-weight: 500;
  }

  .font-bold-400 {
    font-weight: 400;
  }
}
