@import "../../style/variable.scss";

.preview-ai-result-page {
  .loading-container {
    height: 95vh;

    .loading {
      width: 200px;
      height: fit-content;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      background: white;
      border-radius: 16px;
      box-shadow: 0 4px 5px #6363631a;
    }
  }

  .header-section {
    .header {
      font-size: 24px;
    }
  }

  .pdf-document {
    .react-pdf__Page {
      .resizable-box-ocr {
        // border: 0.5px solid getColor("green-1");
        border: 0.5px solid skyblue;
        background: #87cfeb63;
        border-radius: 2px;
      }

      .resizable-box,
      .resizable-box-invoice,
      .resizable-box-packing-list {
        &:not(.error) {
          &.good {
            border: 0.5px solid getColor("green-1");
            background: #17a51563;
            border-radius: 2px;
          }

          &.improve {
            border: 0.5px solid getColor("orange-1");
            background: #ff9b0066;
            border-radius: 2px;
          }

          &.bad {
            border: 0.5px solid getColor("yellow-1");
            background: getColor("yellow-2");
            border-radius: 2px;
          }
        }

        &.error {
          border: 0.5px solid getColor("red-1");
          background: #f9161666;
          border-radius: 2px;
        }
      }
    }
  }

  .overlay {
    .mention-box {
      background-color: white;
      .good {
        color: getColor("green-1");
      }

      .improve {
        color: getColor("orange-1");
      }

      .bad {
        color: getColor("red-1");
      }
      padding: 2px;
      z-index: 10000;
      font-size: 16px;
      width: 260px;
      box-shadow: 0 4px 6px #63636366;
      position: absolute;
      border-radius: 5px;
    }
  }

  .main-preview-section {
    width: 100%;
    .preview-doc-area {
      background: white;
      border-radius: 10px;
      box-shadow: 0 4px 15px #6363631a;

      .preview-result-container {
        max-width: fit-content;
        height: 75vh;
        overflow-y: auto;
        margin: 20px;
        max-height: 95vh;
        background: white;
        padding: 20px;

        /* width */
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          margin-right: 20px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: getColor("gray-1");
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: getColor("primary");
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: getColor("primary-hover");
        }
      }
    }

    .action-button-section {
      background: white;
      border-radius: 10px;
      box-shadow: 0 4px 5px #6363631a;
    }
  }
}

.export-modal {
  .export-modal-header {
    .icon {
      color: getColor("green-1");
      height: 50px;
      border-radius: 8px;
      width: 50px;
      background: #d5ffe3;
    }
  }

  .csv-file-container {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
  }
}
