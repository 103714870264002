@import "../../style/variable.scss";

.upload-page {
  .main-upload {
    min-height: 456px;
  }

  .header-card {
    .title-card-area {
      font-weight: 700;
      font-size: 20px;
    }

    .sub-title {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .fake-button-browse-file {
    border: 1px solid getColor("primary");
    border-radius: 50px;
    color: getColor("primary");
    width: fit-content;

    &.require {
      border: 1px solid getColor("red-1");
      color: getColor("red-1");
    }
  }

  .custom-input-upload {
    text-align: center;
    cursor: pointer;
    min-height: 335px;
    border: 2px solid getColor("primary");
    border-style: dashed;
    border-radius: 6.5px;
    align-items: center;
    display: flex;

    .type-alert {
      font-size: 14px;
    }

    &.require {
      border: 2px solid getColor("red-1");
      border-style: dashed;
    }
  }

  label {
    display: block;
    height: 100%;
  }
}
