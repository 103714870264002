@import "../../style/variable.scss";

.preview-page {
  height: 100vh;

  .row {
    height: inherit;
  }

  .left-side {
    .header-type-file-display {
      height: 65px;
      background: white;
      box-shadow: 0 4px 5px #6363631a;
    }

    .box-display-pdf-area {
      background: white;
      box-shadow: 0 4px 5px #6363631a;
      overflow-x: hidden;

      .invoice-section {
        transform: translate(-120%, 0px);
        transition: all 0.5s ease-in-out;

        &.active {
          transform: translate(10%, 0px);
        }
      }

      .packing-list-section {
        transform: translate(100%, 0px);
        transition: all 0.5s ease-in-out;

        &.active {
          transform: translate(-87%, 0px);
        }
      }
    }

    .tab-type-file {
      min-width: 170px;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      color: white;
      background: getColor("gray-3");
      transition: all 0.5s ease;

      &.active {
        background: getColor("primary");
      }
    }

    .react-pdf__Document {
      max-height: 90vh;
      overflow-y: auto;
      min-width: fit-content;
      margin-bottom: 20px;

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: getColor("gray-1");
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: getColor("primary");
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: getColor("primary-hover");
      }

      .body-page {
        padding: 20px;
        min-width: fit-content;
        margin-top: 20px;
        margin-bottom: 20px;
        background: white;
        border-radius: 16px;
        box-shadow: 0 4px 4px rgba(73, 73, 73, 0.1);
      }

      .react-pdf__Page {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        min-width: 100px;
      }
    }
  }

  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;

    .set-start-end-page {
      position: -webkit-sticky;
      position: fixed;
      top: 20%;
      bottom: 20%;
      height: fit-content;
      margin-left: 5rem;
      transition: all 1s ease-in-out;
      padding: 20px;
      background: #ffffffcc;
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(0.5px);

      .header-text {
        font-size: 20px;
      }

      .description-text {
        font-size: 14px;
        color: #54595e99;
      }

      label {
        font-size: 14px;
        color: getColor("gray-2");
      }

      .close-icon {
        background: #e5e5e5;
        width: fit-content;
        height: fit-content;
        border-radius: 20px;
      }
    }
  }
}
