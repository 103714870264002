.login-page {
  overflow-y: hidden;
  max-height: fit-content;
  margin-top: 15rem;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  transition: all 1s ease-in-out;

  background: rgba(255, 255, 255, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.5px);
  border: 2px solid rgba(113, 113, 113, 0.562);

  .form-label {
    color: black;
    font-weight: bold;
  }
}
